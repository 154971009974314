@import '../../../assets/scss/common/common.scss';

body {
  .course {
    .course-faqs {
      margin-top: 8rem;

      .MuiPaper-root {
        background-color: transparent;
      }

      .title {
        .item {
          display: flex;
          justify-content: center;

          img {
            width: 30px;
          }
        }
      }

      h2 {
        margin-bottom: 0;
      }

      p,
      li {
        font-family: $font-primary;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: 0.2px;
        padding: 0 0 16px;
      }

      .content-faqs {
        margin: 40px 0 24px;
        padding-left: 20px;

        .structure-container,
        .structure-container-full,
        .subsection-container {
          padding: 24px 0;
        }

        .structure {
          border-left: 2px solid transparent;
          &:hover {
            border-left: 2px solid $brand-primary;
          }
        }

        h4 {
          font-family: $font-tertiary;
          line-height: 22px;
        }
      }

      .opened {
        border-left: 2px solid $brand-primary;
        border-bottom: 1px solid #e0e0e0;
      }

      .MuiPaper-elevation1 {
        box-shadow: none;
      }

      .MuiAccordionDetails-root {
        padding: 0;
      }

      .MuiExpansionPanel-root:before {
        background-color: transparent;
      }

      .MuiExpansionPanelSummary-content {
        margin: 0;
      }

      .MuiExpansionPanelSummary-root,
      .labeled-content {
        border-bottom: 1px solid $gray-light;
      }

      .Mui-expanded,
      .labeled-content {
        border-bottom: none;
      }

      .MuiExpansionPanel-root.Mui-expanded {
        margin: 0;
      }
    }
  }
}

@media (max-width: 600px) {
  body {
    .course {
      .course-faqs {
        margin-top: 40px;

        .content-faqs {
          .structure {
            border-left: 2px solid transparent;
          }
        }

        .opened {
          border: none;
        }
      }
    }
  }
}
