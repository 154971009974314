@import '../../../../assets/scss/common/common.scss';

body {
  .menu-list-component {
    .dropdown {
      &.lang {
        padding: 0 0 0 8px;
        text-align: left;
        min-width: 64px;
      }

      &.black {
        background-color: $black;
      }
    }
  }

  .lang-dropdown {
    min-width: 64px;
    padding-left: 8px !important;
  }
}

@media (max-width: 600px) {
  body {
    .lang-dropdown {
      padding-left: 8px !important;
    }
  }
}
