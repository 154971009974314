@import '../../../assets/scss/common/common.scss';

body {
  .courses-gallery {
    margin-top: 16px;
    width: 100%;
  }
}

@media (max-width: 600px) {
  body {
    .courses-gallery {
      .courses-gallery-container {
        > div {
          padding: 8px 0;
        }
      }
      .grid-container {
        .item {
          h2 {
            font-size: 50px;
          }
          .box-content {
            .box-content-container {
              margin: 0 16px;
            }

            .non-over,
            .over {
              display: block;
            }
          }
        }
      }
    }
  }
}
