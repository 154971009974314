@import '../../../assets/scss/common/common.scss';

body {
  .about-box {
    width: 100%;
    margin-top: 16px;
    height: 536px;

    .about-item {
      height: inherit;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      .about-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      h2,
      h3 {
        text-transform: uppercase;
        font-size: 32px;
      }

      h2 {
        font-family: $font-secondary;
        color: $brand-primary;
        letter-spacing: 1px;
        font-size: 50px;
        width: 100%;
        padding: 0;
      }

      h3 {
        font-family: $font-tertiary;
        color: $black;
        margin: 16px 0;
        letter-spacing: 1.2px;
        font-size: 32px;
      }

      h4 {
        font-family: $font-primary;
        font-size: 20px;
        letter-spacing: -0.2px;
        color: $black;
        line-height: 32px;
      }

      .box-content {
        height: inherit;

        .box-content-container {
          height: inherit;
          a {
            display: inline;
          }
        }
      }

      .read-more {
        margin-top: 24px;
      }
    }
  }
}

// IPAD
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  body {
    .about-box {
      .about-item {
        background-position: -100px;

        h3 {
          font-size: 28px;
          width: 90%;
        }

        h4 {
          font-size: 18px;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  body {
    .about-box {
      width: 100%;
      position: relative;
      height: 100vh;
      height: calc(var(--vh-nohead, 1vh) * 100);
      min-height: 640px;

      .about-item {
        background-color: #e7e7e8;
        background-size: auto 70%;
        background-position: center bottom;
        height: 100%;

        .about-content {
          justify-content: flex-start;
          margin-top: 32px;
        }

        .box-content {
          width: auto;
          position: relative;

          h2 {
            font-size: 40px;
            line-height: 36px;
            margin-top: 0;
            letter-spacing: 2.2px;
            width: 60%;
          }

          h3 {
            font-size: 18px;
          }

          h4 {
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.16px;
          }

          .box-content-container {
            margin: 0 0 0 24px;
            width: calc(100% - 64px);
            flex-wrap: wrap-reverse;
          }

          .non-over,
          .over {
            display: block;

            ul {
              display: none;
            }

            .read-more {
              width: calc(100% - 64px);
              position: absolute;
              bottom: 32px;
              padding: 8px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
