@import '../../../../assets/scss/common/common.scss';

.sticky-header {
  position: fixed;
  width: 100%;
  z-index: 5;
  background-color: $gray-extra-light;

  .title,
  .options {
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      font-family: $font-secondary;
      font-size: 26px;
      line-height: 32px;
      letter-spacing: 0.8px;
    }

    h3 {
      font-family: $font-secondary;
      font-size: 12px;
      letter-spacing: 0.6px;
      color: $gray;
    }
  }

  .action {
    text-align: right;
  }

  .action-bar {
    .tabs-container {
      button {
        span {
          font-size: 16px;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .sticky-header {
    top: 108px;
  }
}
