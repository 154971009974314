@import '../../../../assets/scss/common/common.scss';

.partner-modal {
  h2 {
    font-family: $font-secondary;
    font-size: 34px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 1.18px;
    max-width: 280px;
  }

  p.bottom {
    margin: 32px 0 0;
    color: $gray;
    font-size: 14px;

    a {
      font-size: 14px;
    }
  }

  .close {
    position: absolute;
    right: 0;
    top: 0;
  }

  .form-input {
    width: 100%;
    border-radius: 0;
  }

  .btn-primary {
    margin-top: 14px;
  }

  .form-input {
    p {
      margin: 4px 0;
      font-size: 14px;
    }
  }

  small {
    font-family: $font-primary;
    font-size: 13px;
    font-weight: 400;
  }

  p {
    font-family: $font-primary;
    font-size: 16px;
    margin: 16px 0;
    line-height: 22px;

    a {
      display: inline;
      color: $brand-primary;
    }

    &.didnt-receive {
      margin: 0;
    }
  }
}
