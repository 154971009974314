@import '../../../assets/scss/common/common.scss';

body {
  .video-player {
    position: relative;
    height: 100%;
    width: 100%;
    // top: -445px;

    &.fullscreenCont {
      height: auto;
      // top: -480px;
      width: 95%;
      margin: 0 auto;
    }

    .control-buttons {
      position: absolute;
      right: 32px;
      top: 100px;
      height: 30px;
      width: 30px;
      z-index: 99999;
      display: none;
    }

    .video-web,
    .video-mobile {
      right: 0;
      bottom: 0;
      min-width: 100%;
      min-height: 100%;

      height: 100%;
      object-fit: cover;
      object-position: center center;
      width: 100%;
      z-index: -1;
    }

    > .loader {
      position: absolute;
      left: calc(50% - 40px);
      top: 40vh;
    }
  }
}

@media (max-width: 600px) {
  body {
    .video-player {
      video {
        margin-top: 44px;
        // height: calc(100% - 44px) !important;
      }
    }
  }
}
