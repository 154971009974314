@import '../../../assets/scss/common/common.scss';

.menu-container {
  background-color: $black !important;
  top: 100% !important;
  z-index: 5 !important;
}

.main-menu {
  background-color: $black !important;
}

// IPAD
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .menu-container {
    top: auto !important;
  }
}
