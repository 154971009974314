@import '../../assets/scss/common/common.scss';

body {
  .about {
    .container {
      background-color: $white;
    }

    .google-map {
      width: 100%;
      height: 340px;
    }

    .quote {
      margin: 32px 0 40px;

      .left-quote {
        border-left: 1px solid black;
      }

      p {
        margin: 0;
        padding: 0;
      }
    }

    p {
      > a {
        display: inline;
        text-decoration: underline;
      }
    }

    .co-founders {
      text-align: center;

      a.cofounder-link {
        text-transform: uppercase;
        text-decoration: none;
        display: block;

        img {
          max-width: 120px;
        }

        h3 {
          font-family: $font-secondary;
          font-weight: 400;
          font-size: 32px;
          width: 55%;
          margin: 16px auto;
        }

        h4 {
          font-size: 22px;
          font-weight: 400;
          color: $brand-primary;
          width: 40%;
          margin: 16px auto;
          min-height: 44px;
        }

        &:hover {
          opacity: 0.8;
        }
      }
      hr {
        width: 45px;
        margin: 0 auto;
      }
    }

    h2 {
      font-family: $font-secondary;
      font-size: 70px;
      letter-spacing: 6px;
      color: $black;
      width: 50%;
      margin: 0;
      padding-top: 32px;
    }

    .quote-container {
      background-color: #efefef;
      border: 1px solid #ccc;
      padding: 16px;
      text-align: center;
      margin: 80px auto 36px;
      width: 80%;
    }

    p {
      font-size: 19px;
      line-height: 32px;
      font-family: $font-primary;
      margin-bottom: 24px;
    }

    h3 {
      font-size: 20px;
      font-family: $font-primary;
      line-height: 32px;
      font-weight: 600;
      text-transform: uppercase;
    }

    .category-title {
      border-bottom: 1px solid $gray-light;
      margin-bottom: 32px;

      .tabs-container {
        min-height: 48px;
        overflow: initial;

        > div {
          overflow: initial !important;
        }

        .PrivateTabIndicator-root-7 {
          bottom: -2px;
        }
      }

      h2,
      h3,
      h4 {
        font-family: $font-secondary;
        font-size: 52px;
        color: $brand-primary;
        color: $black;
        margin: 0;
        line-height: 80px;
        letter-spacing: 2px;
      }

      h3 {
        font-size: 32px;
      }

      h4 {
        font-size: 22px;
        margin: 0;
      }
    }
  }
}

@media (max-width: 600px) {
  body {
    .page {
      &.about {
        .category-title {
          margin: 0;
          h2 {
            font-size: 40px;
            line-height: 36px;
            letter-spacing: 2.17px;
            width: 100%;
            display: block;
            padding: 32px 0;
          }
        }

        .co-founders {
          a {
            &.cofounder-link {
              margin-top: 40px;
              h3 {
                font-size: 32px;
                line-height: 32px;
                letter-spacing: 1.2px;
                width: 100%;
              }

              h4 {
                font-size: 22px;
                width: 80%;
                letter-spacing: 0.8px;
              }
            }
          }
        }
        .quote {
          margin: 40px 0;
          p {
            font-size: 16px;
            line-height: 20px;
          }
        }

        .final-text {
          h3,
          p {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }
  }
}
