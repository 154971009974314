@import '../../assets/scss/common/common.scss';

body {
  .privacy,
  .terms {
    .container {
      background-color: $white;
    }

    a {
      display: inline;
      font-weight: 600;
    }

    h2 {
      font-family: $font-secondary;
      font-size: 52px;
      letter-spacing: 1.8px;
      color: $black;
      padding: 64px 0 32px 0;
    }

    table {
      width: 100%;
      td {
        border: 1px solid #333;
        padding: 8px;
      }
    }

    p,
    h3,
    strong,
    li,
    h4 {
      font-size: 18px;
      line-height: 24px;
      font-family: $font-primary;
      padding-bottom: 32px;
    }

    h3 {
      font-size: 24px;
      padding-bottom: 16px;
    }

    p {
      font-weight: normal;
    }
  }
}
