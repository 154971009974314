@import '../../../../assets/scss/common/common.scss';

body {
  .page {
    &.course,
    &.degree {
      .course-start-countdown {
        h3 {
          font-size: 28px;
          line-height: 28px;
          letter-spacing: 0.8px;
          margin-top: 8px;
        }

        small {
          font-family: $font-primary;
          font-style: normal;
          font-weight: bold;
          font-size: 10px;
          line-height: 18px;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: $gray-extra-dark;
        }

        .date-container {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;

          .date {
            color: $black;
            border: 1px solid $black;
            padding: 0 4px;
          }
        }

        .counter {
          margin: 16px 0;
          > div {
            border: 2px solid $black;
            height: 88px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            &:nth-child(2) {
              border-right: none;
              border-left: none;
            }
            h3 {
              font-family: $font-tertiary;
              font-style: normal;
              font-weight: bold;
              font-size: 32px;
              line-height: 38px;
              letter-spacing: 0.8px;
            }
            small {
              color: $black;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  body {
    .page {
      &.course,
      &.degree {
        .course-start-countdown {
        }
      }
    }
  }
}
