@import '../../../assets/scss/common/common.scss';

body {
  .page {
    &.home {
      .none-cursor {
        cursor: none;
      }

      .custom-cursor {
        cursor: url('../../../assets/images/circular_play_button.png'), auto !important;
      }

      .circle {
        position: fixed;
        width: 65px;
        height: 65px;
        z-index: 2000;
        transform: translateX(-50%) translateY(-50%);
      }

      .rotateLeft {
        transform: rotate(-45deg);
        transition-duration: 1s;
      }

      .rotateCenter {
        transform: rotate(0deg);
        transition-duration: 1s;
      }

      .rotateRight {
        transform: rotate(45deg);
        transition-duration: 1s;
      }

      .circle-invisible {
        -webkit-transition: 0.4s;
        transition: 0.4s;
        opacity: 0;
        pointer-events: none;
      }

      .jumbotron {
        text-align: left;
        position: relative;

        .content,
        .content-right {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          height: 100vh;
          min-height: 650px;
          padding: 108px 0 5vh;
          margin-bottom: 50px;

          h2 {
            font-family: $font-secondary;
            font-size: 82px;
            color: $brand-primary;
            line-height: 70px;
            letter-spacing: 4px;
          }

          h3 {
            font-family: $font-primary;
            font-size: 19px;
            color: $brand-primary;
            color: $white;
            line-height: 28px;
            letter-spacing: normal;
            margin: 32px 0;
          }
        }

        .scroll-container {
          position: absolute;
          right: 0;
          bottom: calc(50% - 105px);
          width: 22px;
          height: 231px;
          //animation: bounce 400ms ease-in alternate infinite;

          .scroll {
            background-image: url('../../../assets/images/scroll.svg');
            width: inherit;
            height: inherit;
            display: inline-block;

            @include fade(1400);
          }
        }

        .video-mute-icon,
        .video-replay-icon {
          width: 38px;
          height: 38px;
          cursor: pointer;
          opacity: 0.8;
          &:hover {
            opacity: 1;
          }
        }

        .video-mute-icon {
          display: none;
        }

        .video-replay-icon {
          display: block;
        }

        .content-right {
          /*display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          margin-bottom: 70px;*/
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          height: 100vh;
          min-height: 650px;
          margin-top: 100px;
        }

        &.stop {
          .content {
            h2 {
              font-size: 40px;
              line-height: 0.9;
              letter-spacing: 2.05px;
              margin-bottom: 0;
            }

            h3 {
              display: none;
            }

            .create-acount-button {
              margin-top: 16px;
            }
          }

          .video-replay-icon,
          .scroll-container {
            display: none;
          }

          .video-mute-icon {
            display: block;
          }
        }
      }
    }
  }
}

// IPAD
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  body {
    .page {
      &.home {
        .jumbotron {
          .content,
          .content-right {
            height: 100vh;
            height: calc(var(--vh, 1vh) * 100);
            margin-bottom: 70px;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  body {
    .page {
      &.home {
        .jumbotron-container {
          margin-bottom: 16px;
          overflow: hidden;
          clear: both;
        }

        .jumbotron {
          overflow: hidden;
          .content {
            position: relative;
            height: 100vh;
            height: calc(var(--vh, 1vh) * 100);
            padding: 132px 0 0 0;
            justify-content: flex-start;

            h2 {
              font-size: 54px;
              line-height: 42px;
              letter-spacing: 2px;
              margin-bottom: 16px;
            }

            h3 {
              font-size: 16px;
              line-height: 20px;
              letter-spacing: normal;
              margin: 0;
              width: calc(100% - 46px);
            }

            .create-account {
              position: absolute;
              bottom: calc(var(--vh, 1vh) * 2);
              width: 100%;
              padding: 8px;
              text-align: center;
            }
          }

          &.stop {
            .content {
              h2 {
                font-size: 32px;
                line-height: 0.9;
                letter-spacing: 2px;
              }
            }
          }

          .content-right {
            padding: 132px 0 calc(var(--vh, 1vh) * 2);
            height: 100vh;
            height: calc(var(--vh, 1vh) * 100);
            position: absolute;
            right: 0;
            bottom: 64px;
            margin-bottom: 90px;
          }
        }
      }
    }
  }
}
