@import '../../../assets/scss/common/common.scss';

body {
  .enrollment {
    background-color: $black;
  }
  .slider-courses {
    margin: 0 0 16px;
    display: flex;
    flex-direction: row;
    position: relative;

    .content {
      display: none;
      height: inherit;

      background-repeat: no-repeat;
      background-attachment: inherit;
      background-position: center;
      background-size: cover;
      height: inherit;

      .content-container {
        height: inherit;
        display: flex;

        .content-box {
          .author-content {
            display: flex;
            align-items: flex-end;

            h2 {
              margin-bottom: 16px;
            }
          }

          h2,
          h3 {
            font-family: $font-secondary;
            text-transform: uppercase;
            color: $brand-primary;
          }

          h2 {
            font-size: 100px;
          }

          h3 {
            font-size: 24px;
          }

          p {
            font-family: $font-primary;
            text-transform: none;
            font-size: 14px;
            line-height: 24px;
            color: #b1b1b1;
          }
        }
      }
      &:first-child {
        display: flex;
      }
    }

    .arrow {
      mask: url('../../../assets/images/arrow-slider.svg');
      width: 40px;
      height: 24px;
      border: none;
      display: block;
      position: absolute;
      cursor: pointer;
      mask-size: cover;
      background: $brand-primary;
      top: 50%;
      z-index: 100;

      &.left {
        left: 0;
      }

      &.right {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        margin: 0 0 0 auto;
        right: 0;
      }

      &:hover,
      &:focus {
        outline: none;
        background-color: $white;
      }
    }

    .slide {
      width: 290px;
      height: 180px;
      margin-right: 16px;

      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: flex-start;

      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      &:hover {
        opacity: 0.9;

        .continue {
          visibility: visible;
        }
      }

      > div {
        display: flex;
        padding-left: 24px;
        height: inherit;
        flex-direction: column;
        justify-content: space-around;
      }

      h2,
      h3 {
        text-transform: uppercase;
        font-size: 18px;
        display: block;
      }

      h2 {
        font-family: $font-secondary;
        color: $brand-primary;
        letter-spacing: 1px;
        font-size: 24px;
        width: 80%;
      }

      h3 {
        font-family: $font-tertiary;
        color: $white;
        font-size: 16px;
        letter-spacing: 0.6px;
        line-height: 32px;
      }

      .continue {
        //visibility: hidden;
        max-width: 120px;
        padding: 0 8px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

@media (max-width: 600px) {
  body {
    .slider-courses {
      .slide {
        width: 100%;

        h2 {
          width: 100%;
        }
      }
    }
  }
}
