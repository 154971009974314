@import '../../../assets/scss/common/common.scss';

body {
  .course,
  .degree {
    .jumbotron {
      text-align: left;
      position: relative;
      min-height: 650px;

      .content,
      .content-right {
        display: flex;
        flex-direction: column-reverse;
        height: 100vh;
        min-height: 650px;
        padding: 108px 0 5vh;
        align-items: flex-start;

        .footer-text {
          font-size: 12px;
          line-height: 12px;
          font-family: $font-secondary;
          margin-top: 33px;
          color: $white;
          text-align: left;
        }

        a {
          color: $gray;
          text-transform: uppercase;
          font-size: 14px;
          letter-spacing: 0.6px;

          &.back-button {
            display: flex;
            margin: 5vh 0 10vh;
            align-items: center;

            img {
              margin-right: 8px;
              margin-top: -4px;
            }

            @include fade(300);
          }

          &.buy-course {
            color: $black;
            text-align: center;
          }
        }

        h1 {
          font-family: $font-secondary;
          font-size: 116px !important;
          color: $brand-primary;
          line-height: 116px !important;
          letter-spacing: 4.2px;
          @include fade(500);
          width: 42rem;
          font-weight: 400;
        }

        h2,
        h3 {
          font-family: $font-tertiary;
          text-transform: uppercase;
          font-size: 32px;
          color: $brand-primary;
          color: $white;
          line-height: 28px;
          margin: 16px 0;
        }

        h2 {
          letter-spacing: 1.2px;
          @include fade(700);
        }

        /*h3 {
          color: $gray-dark;
          @include fade(300);
        }*/

        p {
          margin-top: 24px;
          font-size: 18px;
          color: $white;
          font-family: $font-primary;
          line-height: 24px;
          letter-spacing: 0.2px;
          padding: 0 0 0 10px;
          border-left: 2px solid $white;
          @include fade(700);
          width: 75%;
        }

        .sponsors {
          flex: 1;
          margin-top: 50px;
        }
      }

      .content-top-control {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        height: 100vh;
        min-height: 650px;
        padding-top: 15em;
      }

      .content-right-control {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        min-height: 650px;
        padding-top: 15em;
      }

      .content-right {
        position: relative;
        align-items: flex-start;
        flex-direction: row;
        justify-content: flex-end;

        .get-course-program,
        .buy-course-container {
          margin-top: 5vh;
          display: inline-block;
        }
      }

      .video-mute-icon,
      .video-replay-icon {
        width: 38px;
        height: 38px;
        cursor: pointer;
        opacity: 0.8;
        &:hover {
          opacity: 1;
        }
      }

      .video-mute-icon {
        display: none;
      }

      .video-replay-icon {
        display: block;
      }

      &.stop {
        .content {
          justify-content: flex-end;

          h1 {
            font-size: 116px;
            line-height: 0.8;
            letter-spacing: 2px;
            margin-bottom: 0;
            max-width: 300px;
          }

          .back-button,
          h1,
          h3,
          h2,
          p {
            display: none;
          }
        }

        .video-replay-icon,
        .scroll-container {
          display: none;
        }

        .video-mute-icon {
          display: block;
        }
      }
    }

    .buy-course {
      min-width: 176px;
    }

    .control-buttons {
      position: absolute;
      bottom: 5vh;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  body {
    .home-background {
      background-position: right;
    }

    .course,
    .degree {
      .jumbotron {
        .content {
          h1 {
            font-size: 116px;
            line-height: 60px;
            letter-spacing: 2.17px;
            width: 30rem;
          }

          h2,
          h3 {
            font-size: 24px;
            line-height: 10vh;
            margin: 0;
            color: $white;
          }

          h3 {
            margin-top: 10%;
          }
        }

        .content-right-control {
          display: none !important;
        }

        .content-top-control {
          display: flex;
          flex-direction: column;
          justify-content: start;
          align-items: center;
          height: 100vh;
          min-height: 650px;
          padding-top: 15em;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  body {
    .course,
    .degree {
      .jumbotron {
        position: relative;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        min-height: 640px;

        .content {
          height: 90vh;
          height: calc(var(--vh, 1vh) * 95);
          min-height: auto;
          padding: 0;

          .footer-text {
            font-size: 12px;
            text-align: center;
            width: 100%;
            font-family: $font-secondary;
          }
          /*padding-bottom: calc(var(--vh, 1vh) * 30);*/

          a.back-button {
            display: none;
          }

          h1 {
            font-size: 32px;
            line-height: 30px;
            letter-spacing: 2.17px;
          }

          h2,
          h3 {
            font-size: 18px;
            line-height: 32px;
            margin: 0;
            color: $white;
          }

          h3 {
            color: $white;
          }

          p {
            margin-top: 8px;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0;
            padding: 0 0 0 16px;
          }

          h1,
          h2,
          h3,
          p {
            width: 80%;
          }

          .sponsors {
            display: none;
          }
        }

        .content-right {
          position: relative;
          height: 10vh;
          min-height: auto;
          padding: 0;
          justify-content: center;

          .get-course-program,
          .buy-course-container {
            padding: 0;
            width: 100%;
            text-align: right;
            margin: 0;
            height: 46px;
            bottom: calc(var(--vh, 1vh) * 18);
            position: absolute;

            .buy-course {
              display: block;
            }

            .buy-button > p {
              text-align: right;
            }
          }
        }

        .content-right-control {
          display: none;
        }

        .content-top-control {
          display: none;
        }

        .control-buttons {
          position: absolute;
          bottom: calc(var(--vh, 1vh) * 8);
          right: 0;
        }

        &.stop {
          .content {
            h1 {
              margin-bottom: 8px;
            }

            h2 {
              font-size: 40px;
              line-height: 0.9;
              letter-spacing: 2.05px;
              margin-bottom: 64px;
            }
          }
        }
      }
    }
  }
}
