// @import "reset";
@import 'variables';
@import 'fonts';
@import 'material-ui-overrides';
@import 'animations';

@import '../library/vivify';

html,
body,
body > div,
.App {
  height: 100%;
}

* {
  margin: 0;
}

h1,
h2,
h3,
h4 {
  font-weight: 400;
}

body {
  font-family: $font-tertiary;
  background-color: $white;

  p {
    strong,
    b {
      font-weight: 600;
    }

    i {
      font-style: italic;
    }
  }

  .page-wrap {
    min-height: 100%;
    display: block;
    margin-bottom: 0;
    position: relative;
  }

  .page-wrap:after {
    content: '';
    display: block;
  }

  #launcher {
    top: 150px !important;
    right: 0 !important;
    bottom: auto !important;
    left: auto !important;
    z-index: 4 !important;
  }

  .hide {
    display: none !important;
  }

  a {
    text-decoration: none;
    color: inherit;
    display: flex;
  }

  .page {
    margin-top: 108px;

    min-height: 100%;
    margin-bottom: -540px;

    &:after {
      content: '';
      display: block;
      height: 540px;
    }
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .background-white {
    background-color: $white;
  }

  .btn-primary,
  .btn-secondary,
  .btn-secondary-white,
  .btn-black-white,
  .btn-tertiary,
  .btn-white,
  .btn-black {
    cursor: pointer;
    background-color: $brand-primary;
    text-decoration: none;
    font-family: $font-secondary;
    text-transform: uppercase;
    padding: 12px 32px;
    display: inline-block;
    font-size: 14px;
    line-height: 24px;
    color: $black;
    font-weight: 400;
    letter-spacing: 0.8px;
    border: 0;
    position: relative;
    border-radius: 0;

    > span {
      display: inline;
    }

    &.arrow-right,
    &.arrow-left,
    &.arrow-up,
    &.arrow-down {
      &::after {
        background: url('../../images/arrow-right.svg') no-repeat scroll center
          center / 100% auto rgba(0, 0, 0, 0);
        content: '';
        display: inline-block;
        height: 30px;
        vertical-align: middle;
        width: 20px;
      }

      &.long {
        &::after {
          background: url('../../images/arrow-right-long.svg') no-repeat scroll
            center center / 100% auto rgba(0, 0, 0, 0);
          width: 40px;
          margin-left: 18px;
        }
      }
    }

    &.arrow-left {
      &::after {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
    &.arrow-at-left {
      &::before {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
    &.arrow-up {
      &::after {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
      }
    }
    &.arrow-down {
      &::after {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
      }
    }

    &.sm {
      font-size: 14px;
      padding: 0 24px;
    }

    &.md {
      font-size: 14px;
      padding: 4px 12px;

      &.arrow-right,
      &.arrow-left,
      &.arrow-up,
      &.arrow-down {
        &::after {
          background: url('../../images/arrow-right.svg') no-repeat scroll
            center center/60% auto rgba(0, 0, 0, 0);
          margin-left: 8px;
        }

        &.long {
          &::after {
            background: url('../../images/arrow-right-long.svg') no-repeat
              scroll center center / 100% auto rgba(0, 0, 0, 0);
            margin-left: 24px;
          }
        }
      }
    }

    &.lg {
      font-size: 32px;
    }
  }

  .btn-black {
    &:active {
      background-color: $black;
      &.sm {
        &.arrow-right,
        &.arrow-left,
        &.arrow-up,
        &.arrow-down {
          &::after {
            background-color: $white;
          }
        }
      }
    }
  }

  .btn-link {
    text-decoration: none;
    font-family: $font-secondary;
    text-transform: uppercase;
    padding: 12px 32px;
    display: inline-block;
    font-size: 14px;
    line-height: 24px;
    color: $black;
    font-weight: 400;
    letter-spacing: 0.8px;
    border: 0;
    position: relative;
    border-radius: 0;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    &:active {
      background-color: $black;
      &.sm {
        &.arrow-right,
        &.arrow-left,
        &.arrow-up,
        &.arrow-down,
        &.arrow-at-left {
          &::after {
            background-color: rgba(0, 0, 0, 0);
          }
        }
      }
    }
    &.arrow-at-left {
      &::before {
        background: url('../../images/arrow-right-long.svg') no-repeat scroll
          center center / 100% auto rgba(0, 0, 0, 0);
        content: '';
        display: inline-block;
        height: 30px;
        vertical-align: middle;
        width: 20px;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
      }

      &.long {
        &::before {
          background: url('../../images/arrow-right-long.svg') no-repeat scroll
            center center / 100% auto rgba(0, 0, 0, 0);
          color: white;
          width: 40px;
          margin-right: 20px;
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }
    }
    &:hover {
      cursor: pointer;
      &::before {
        background: url('../../images/arrow-right-long-white.svg') no-repeat
          scroll center center / 100% auto rgba(0, 0, 0, 0);
        content: '';
        display: inline-block;
        height: 30px;
        vertical-align: middle;
        width: 20px;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
      }

      &.long {
        &::before {
          background: url('../../images/arrow-right-long-white.svg') no-repeat
            scroll center center / 100% auto rgba(0, 0, 0, 0);
          color: white;
          width: 40px;
          margin-right: 20px;
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }
      background-color: rgba(0, 0, 0, 0);
      color: $white;
      &.sm {
        &.arrow-right,
        &.arrow-left,
        &.arrow-up,
        &.arrow-down {
          &::after {
            background-color: $white;
          }
        }
      }
    }
    &.sm {
      font-size: 14px;
      padding: 0 24px;
    }

    &.md {
      font-size: 14px;
      padding: 4px 12px;
    }

    &.lg {
      font-size: 32px;
    }
  }

  .btn-primary,
  .btn-black {
    &:active {
      background-color: $black;

      &.sm {
        &.arrow-right,
        &.arrow-left,
        &.arrow-up,
        &.arrow-down {
          &::after {
            background-color: $white;
          }
        }
      }
    }

    &.sm {
      padding: 4px 24px;

      &.arrow-right,
      &.arrow-left,
      &.arrow-up,
      &.arrow-down {
        &::after {
          background: url('../../images/arrow-right.svg') no-repeat scroll
            center center/40% auto rgba(255, 255, 255, 0);
          position: absolute;
          background-color: $white;
          right: -32px;
          background-color: $white;
          padding: 0;
          top: 4px;
          width: 32px;
          height: 24px;
        }
      }
    }
  }

  .btn-primary,
  .btn-white {
    &:active {
      background-color: #6de5ff;
      &.sm {
        &.arrow-right,
        &.arrow-left,
        &.arrow-up,
        &.arrow-down {
          &::after {
            background-color: #6de5ff;
          }
        }
      }
    }

    &.sm {
      padding: 4px 24px;

      &.arrow-right,
      &.arrow-left,
      &.arrow-up,
      &.arrow-down {
        &::after {
          background: url('../../images/arrow-right.svg') no-repeat scroll
            center center/40% auto rgba(0, 0, 0, 0);
          position: absolute;
          background-color: $brand-primary;
          right: -32px;
          background-color: #52e0ff;
          padding: 0;
          top: 4px;
          width: 32px;
          height: 24px;
        }
      }
    }
  }

  .btn-black {
    &:active {
      background-color: $black;
      &.sm {
        &.arrow-right,
        &.arrow-left,
        &.arrow-up,
        &.arrow-down {
          &::after {
            background-color: $white;
          }
        }
      }
    }

    &:hover {
      background-color: #383838;
      color: $white;

      &.sm {
        &.arrow-right,
        &.arrow-left,
        &.arrow-up,
        &.arrow-down {
          &::after {
            background-color: $white;
          }
        }
      }
    }
  }

  .btn-white {
    &:active {
      background-color: #a5a5a5;
      &.sm {
        &.arrow-right,
        &.arrow-left,
        &.arrow-up,
        &.arrow-down {
          &::after {
            background-color: #a5a5a5;
          }
        }
      }
    }

    &:hover {
      background-color: #d8d8d8;

      &.sm {
        &.arrow-right,
        &.arrow-left,
        &.arrow-up,
        &.arrow-down {
          &::after {
            background-color: #d8d8d8;
          }
        }
      }
    }
  }

  .btn-primary {
    &:hover {
      background-color: #00c3ee;
      border: none;

      &.sm {
        &.arrow-right,
        &.arrow-left,
        &.arrow-up,
        &.arrow-down {
          &::after {
            background-color: $white;
          }
        }
      }
    }
  }

  .btn-black,
  .btn-secondary {
    background-color: $black;
    color: $white;
    cursor: pointer;
    border: 2px solid $black;
    &.sm {
      &.arrow-right,
      &.arrow-left,
      &.arrow-up,
      &.arrow-down {
        &::after {
          background-color: $white;
        }
      }
    }
  }

  .btn-white,
  .btn-secondary {
    background-color: $white;
    cursor: pointer;

    &.sm {
      &.arrow-right,
      &.arrow-left,
      &.arrow-up,
      &.arrow-down {
        &::after {
          background-color: $white;
        }
      }
    }
  }

  .btn-secondary,
  .btn-tertiary {
    background-color: transparent;
    color: $brand-primary;

    &.dark {
      color: $black;
      border: 2px solid $black;
      height: 38px;
      padding-top: 4px !important;

      &:active {
        color: $black;
        border-color: $black;
        background-color: #d8d8d8;
      }
    }

    &:active {
      color: $white;
      border-color: $white;
    }

    .sm {
      &:not(.dropdown) {
        padding: 4px 24px;
      }
    }
  }

  .btn-secondary-white,
  .btn-tertiary {
    background-color: transparent;
    color: $white;

    &.dark {
      color: $black;
      border: 2px solid $black;
    }

    &:active {
      background-color: #747575;
      /*border-color: $brand-primary;*/
      border: none;
    }

    &:hover {
      background-color: #898989;
      border: none;
    }

    .sm {
      &:not(.dropdown) {
        padding: 4px 24px;
      }
    }
  }

  .btn-black-white {
    background-color: transparent;
    color: $white;

    &.dark {
      color: $black;
      border: 2px solid $black;
    }
  }

  .btn-secondary {
    border: 1px solid $brand-primary;
  }

  .btn-secondary-white {
    border: 1px solid $white;
  }

  .btn-black-white {
    border: 1px solid $white;
  }

  .btn-secondary,
  .btn-tertiary {
    &.sm,
    &.md {
      &.arrow-right,
      &.arrow-left,
      &.arrow-up,
      &.arrow-down {
        &::after {
          transition-duration: 0.8s;
          transition-property: transform;
          background: url('../../images/arrow-right-blue.svg') no-repeat scroll
            center center/60% auto rgba(0, 0, 0, 0);
          margin-left: 8px;
        }
      }
      &.arrow-at-left {
        &::before {
          transition-duration: 0.8s;
          transition-property: transform;
          background: url('../../images/arrow-right-blue.svg') no-repeat scroll
            center center/60% auto rgba(0, 0, 0, 0);
          margin-left: 8px;
        }
      }
    }

    &.white {
      border: 1px solid $white;
      color: $white;

      &.sm,
      &.md {
        &.arrow-right,
        &.arrow-left,
        &.arrow-up,
        &.arrow-down {
          &::after {
            background: url('../../images/arrow-right-white.svg') no-repeat
              scroll center center/60% auto rgba(0, 0, 0, 0);
            margin-left: 8px;
          }
        }
        &.arrow-at-left {
          &::before {
            transition-duration: 0.8s;
            transition-property: transform;
            background: url('../../images/arrow-right-blue.svg') no-repeat
              scroll center center/60% auto rgba(0, 0, 0, 0);
            margin-left: 8px;
          }
        }
      }
    }

    &.dark {
      &.sm,
      &.md {
        &.arrow-right,
        &.arrow-left,
        &.arrow-up,
        &.arrow-down {
          &::after {
            background: url('../../images/arrow-right-black.svg') no-repeat
              scroll center center/60% auto rgba(0, 0, 0, 0);
            margin-left: 8px;
          }
        }
      }
    }

    &.partial-dark {
      color: $black;

      &.sm,
      &.md {
        &.arrow-right,
        &.arrow-left,
        &.arrow-up,
        &.arrow-down {
          &::after {
            background: url('../../images/arrow-right-black.svg') no-repeat
              scroll center center/60% auto rgba(0, 0, 0, 0);
            margin-left: 8px;
          }
        }
      }
    }
  }
}

// IPAD
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  html,
  body {
    overflow-x: hidden;
    max-width: 100%;
    -webkit-overflow-scrolling: auto;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

// MOBILE
@media (max-width: 600px) {
  .page {
    overflow-x: hidden;
  }

  html,
  body {
    max-width: 100%;
    -webkit-overflow-scrolling: auto;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    overflow-x: hidden;

    .page {
      margin-top: 86px;
    }

    #launcher {
      top: 120px !important;
      margin: 0 8px 0 0 !important;
    }
  }
}
