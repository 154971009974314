@import '../../assets/scss/common/common.scss';

body {
  .link {
    color: $white;
  }
  .text-white {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    font-family: $font-secondary;
  }

  .allCourse {
    text-align: center;
    margin-top: 100px !important;
    margin-bottom: 200px !important;
  }

  .home-background-back {
    background-color: $black;
    height: 100vh;
    min-height: 650px;
    top: 0;
    z-index: 0;
    position: absolute;
    width: 100%;
  }

  .home-background {
    width: 100%;
    // background-image: url('../../assets/images/bg-home.png');
    background-repeat: no-repeat;
    background-attachment: inherit;
    /*background-position: center;*/
    background-size: cover;
    height: 100vh;
    min-height: 650px;
    background-color: transparent;

    ._pattern-overlay,
    ._pattern-overlay-detail,
    ._background-dark {
      position: absolute;
      top: 0;
      width: 100%;
      opacity: 0.9;
      bottom: 0;
      background-image: url('../../assets/images/vegas_overlay.png');
      z-index: 1;

      &.mobile {
        opacity: 0.8;
        background-image: url('../../assets/images/video_overlay_mobile.png');
      }
    }

    ._pattern-overlay-detail {
      background-image: url('../../assets/images/vegas_overlay_fine.png');
      opacity: 0.3;

      &.mobile {
        opacity: 0.3;
        background-image: url('../../assets/images/vegas_overlay_fine.png');
      }
    }

    ._background-dark {
      opacity: 0.2;
      z-index: 2;
      background-image: none;
      background-color: $black;
    }

    .video-container {
      height: 100%;
      overflow: hidden;
      position: relative;
    }

    .video-web,
    .video-mobile {
      right: 0;
      bottom: 0;
      min-width: 100%;
      min-height: 100%;

      height: 100%;
      object-fit: cover;
      object-position: center center;
      width: 100%;
      z-index: -1;
    }
  }

  .page {
    &.home {
      z-index: 1;
      position: relative;
      margin-top: 0;

      .enrollment {
        padding-top: 0;
        padding-bottom: 0;
        top: 108px;
        position: absolute;
        width: 100%;
        z-index: 1;

        .title {
          display: flex;
          align-items: center;
          border-top: 1px solid $gray-extra-dark;
          margin-top: 16px;

          h2 {
            font-family: $font-secondary;
            font-size: 29px;
            letter-spacing: 1px;
            color: $white;
            line-height: 45px;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  body {
    .allCourse {
      text-align: center;
      margin-top: 20px !important;
      margin-bottom: 20px !important;
    }

    .page {
      &.home {
        .enrollment {
          display: none;
        }
      }
    }

    .home-background-back,
    .home-background {
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
    }
  }
}
