/* EuropaType Font Software (MONO45 Headline) — is owned by EuropaType. EuropaType Font Software is protected by copyright, use without permission is illegal. This CSS file using EuropaType Font Software has been generated and served by PLATFORM MEDIA LAB SA («OXYGEN») and is authorised for use on www.oxygen.education only. ©2020, europatype.com */
@font-face {
  font-family: 'mono45-headline';
  src: url('../../fonts/mono/mono45-headline-webfont.woff2') format('woff2'),
    url('../../fonts/mono/mono45-headline-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: 'DIN Condensed';
  src: url('../../fonts/din/DINCondensed-Bold.eot');
  src: url('../../fonts/din/DINCondensed-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/din/DINCondensed-Bold.woff2') format('woff2'),
    url('../../fonts/din/DINCondensed-Bold.woff') format('woff'),
    url('../../fonts/din/DINCondensed-Bold.ttf') format('truetype'),
    url('../../fonts/din/DINCondensed-Bold.svg#DINCondensed-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: 'D-DIN';
  src: url('../../fonts/din/D-DIN.woff2') format('woff2'),
    url('../../fonts/din/D-DIN.woff') format('woff'),
    url('../../fonts/din/D-DIN.ttf') format('truetype'),
    url('../../fonts/din/D-DIN.svg#D-DIN') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: 'D-DIN';
  src: url('../../fonts/din/d-din-bold-webfont.woff2') format('woff2'),
    url('../../fonts/din/d-din-bold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: bold;
  unicode-range: U+000-5FF;
}
