@import '../../../assets/scss/common/common.scss';

.verify-site-wide {
  width: 100%;
  height: 30px;
  top: 108px;
  font-family: $font-primary;
  padding-top: 4px;

  background-color: white;
  z-index: 5;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;

  .site-wide-container {
    height: 20px;
    font-size: 12px;
    line-height: 16px;
    display: block;
    width: 100%;
    text-align: center;
    cursor: pointer;

    img {
      margin-right: 8px;
    }
  }
}

.verify-site-modal {
  h2 {
    font-family: $font-secondary;
    font-size: 34px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 1.18px;
    max-width: 210px;
  }

  .close {
    position: absolute;
    right: 0;
    top: 0;
  }

  .form-input {
    width: 100%;
    border-radius: 0;
  }

  .btn-primary {
    margin-top: 14px;
  }

  p {
    font-family: $font-primary;
    font-size: 16px;
    margin: 16px 0;

    a {
      display: inline;
      color: $brand-primary;
    }

    &.didnt-receive {
      margin: 0;
    }
  }
}

@media (max-width: 600px) {
  body {
    .verify-site-wide {
      top: 80px;
      height: auto;
      z-index: 10;
      img {
        display: none;
      }
      .site-wide-container {
        font-size: 11px;
        padding: 2px 0;
      }
    }
  }
}
