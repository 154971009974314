@import '../../../assets/scss/common/common.scss';

$columnWidth: 110px;

.menu {
  display: flex;
  justify-content: center;
  align-items: center;

  .search-container {
    margin-top: 40px;
    margin-bottom: 8px;

    .main-search {
      width: 330px;
    }
    .icon-search {
      margin-top: -2px;
      margin-left: -70px;
    }

    .explore-courses {
      color: white;
      border-color: white;
    }
  }

  .menu-mobile {
    hr {
      border-color: $gray-dark;
    }

    h2,
    h3 {
      line-height: 40px;
      font-size: 24px;
      font-family: $font-secondary;
      color: $brand-primary;
    }

    h3 {
      color: $white;
    }
  }

  .main-menu-container {
    ul {
      li {
        font-family: $font-secondary;
        color: $gray-dark;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        padding: 6px 0;
        line-height: 22px;

        &:hover {
          color: $brand-primary;
        }
        &:first-child {
          padding-top: 32px;
        }
        &:last-child {
          padding-bottom: 72px;
        }

        a {
          font-size: 17px;
        }

        &.no-link {
          cursor: default;
          &:hover {
            color: $white;
          }
        }

        &.empty {
          color: white;
          cursor: none;
          &:hover {
            color: white;
          }
        }
      }
    }
  }

  .menu-content {
    min-height: 530px;
  }
}

.dropdown-menu {
  // direction:rtl;
  overflow: hidden;
}

.dropdown-menu,
.search-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
  border: none;
  cursor: pointer;
  position: relative;
  height: 500px;

  li {
    display: block;
    width: $columnWidth;
    text-decoration: none;
    text-align: left;

    font-family: $font-secondary;
    color: $gray-dark;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 45px;

    &:hover {
      color: $white;
    }
  }
}

.menu {
  .main-menu-container {
    .search-menu {
      height: auto;
      li {
        width: auto;
        &.no-link {
          color: $gray-light;
          cursor: default;
          &:hover {
            color: $gray-light;
          }
        }
        &:last-child {
          border-bottom: 1px solid $gray-dark;
        }
      }
    }
  }
}

/*--- Sublist Styles ---*/
.dropdown-menu ul {
  position: absolute;
  display: none;
  margin-left: $columnWidth;
  top: 0;
  margin-top: 0;
  height: 160px;
  padding: 0 16px;

  &:after {
    content: '';
    background: $gray-dark;
    position: absolute;
    bottom: 0;
    left: 0;
    height: calc(100% - 30px);
    width: 1px;
  }
}

/*--- Hide Sub Sublists ---*/
.dropdown-menu li:hover ul ul {
  display: none;
}

.dropdown-menu li:hover ul ul ul {
  display: none;
}

.dropdown-menu li ul ul ul li {
  width: 230px;
}

/*--- Sublevel UL's display and position on hover ---*/
.dropdown-menu li:hover ul {
  display: block;
}
.dropdown-menu li li:hover ul {
  margin-left: $columnWidth;
  top: 0;
  display: block;
}

.dropdown-menu li li li:hover ul {
  margin-left: $columnWidth;
  top: 0;
  display: block;
}
nu-li {
  padding: 10px;
}

.dropdown-submenu {
  position: absolute;
  left: 0px;
  top: 0px;
}

// IPAD
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  body {
    .search-menu {
      li {
        width: auto;
      }
    }
  }
}

@media (max-width: 600px) {
  body {
    .menu {
      color: $white;
      .main-menu-container {
        margin: 0;

        .search-container {
          margin-top: 32px;
          margin-bottom: 8px;

          .main-search {
            width: 100%;
            margin-right: 0;
          }

          .explore-courses {
            width: 100%;
            padding-right: 0;
            padding-left: 0;
            text-align: right;
            border: none;
          }
        }
      }
    }
  }
}
