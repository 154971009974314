@keyframes slideTop {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes simpleFade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideTopOpacity {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes bounce {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(10px);
  }
}

@keyframes slideInFromBottom {
  from {
    transform: translateY(80%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@mixin fade($delay) {
  animation: simpleFade unquote(($delay + 200)+'ms');
  animation-delay: unquote($delay + 'ms');
  animation-fill-mode: both;
}

@mixin button() {
  transition: transform 1s linear;
  display: block;

  span {
    &:hover {
      transform: translateX(15px);
    }
  }
}
