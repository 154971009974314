@import '../../../assets/scss/common/common.scss';

body {
  .content-slider {
    margin: 16px 0;
    height: 840px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    overflow: hidden;
    position: relative;

    .anchor {
      position: absolute;
      top: -50px;
    }

    .content-slider-shadow {
      height: inherit;
      background-image: linear-gradient(to left, rgba(0, 0, 0, 0), black);
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    h2 {
      font-family: $font-secondary;
      font-size: 70px;
      color: $brand-primary;
      letter-spacing: 4.2px;
      line-height: 72px;
    }

    .content-slider-container {
      .content {
        .slider-image {
          width: 100%;
        }

        .item {
          height: 80px;
          position: relative;

          &:first-child {
            margin-top: 40px;
          }

          > div {
            align-items: center;
            display: flex;
          }

          p {
            font-family: $font-primary;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.2px;
            color: $white;
            margin-left: 16px;
          }

          h2 {
            font-size: 50px;
            color: $white;
          }

          /*
          &:first-child {
            h2 {
              font-size: 50px;
              color: $brand-primary;
            }

            p {
              color: $brand-primary;
            }
          }
          */
        }
      }
    }
  }
}

@media (max-width: 600px) {
  body {
    .content-slider {
      height: 100vh;
      height: calc(var(--vh-nohead, 1vh) * 100);
      min-height: 740px;

      h2 {
        font-size: 56px;
        line-height: 50px;
        margin-top: 10%;
      }

      .content-slider-shadow {
        height: 100%;
      }

      .content-slider-container {
        height: 100%;
        padding: 0 16px;

        .content {
          height: 100%;

          .item {
            height: auto;
            margin: 0;

            &:first-child {
              margin-top: 0;
            }

            h2 {
              margin-top: 8px;
              font-size: 36px;
              color: $white;
            }

            p {
              font-size: 14px;
              line-height: 18px;
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}
