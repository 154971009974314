@import '../../../assets/scss/common/common.scss';

body {
  .player-container {
    h3 {
      font-family: $font-secondary !important;
      text-transform: uppercase;
      font-size: 20px !important;
      color: $brand-primary !important;
      line-height: 20px;
      letter-spacing: 0.04em;
    }

    h4 {
      font-family: $font-secondary !important;
      text-transform: uppercase;
      font-size: 16px !important;
      color: #fff !important;
      line-height: 16px;
      letter-spacing: 1px;
      margin-top: 10px;
      text-decoration: underline;
    }

    h5 {
      font-family: $font-secondary !important;
      text-transform: uppercase;
      font-size: 12px !important;
      color: #a5a5a5 !important;
      line-height: 12px;
      letter-spacing: 1px;
    }

    .MuiSlider-root {
      margin-top: -10px;
    }

    .tabs-menu:hover {
      cursor: pointer;
    }
  }
}
