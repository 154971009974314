@import '../../../assets/scss/common/common.scss';
@import '../../../assets/scss/common/variables';

body {
  .course {
    .full-course-program {
      .share-button {
        width: 100%;
        padding: 4px 0;
        text-align: center;
        margin-top: 16px;
      }

      .full-content-course {
        .unit-container {
          padding-left: 15px;
        }
      }

      .MuiPaper-elevation1 {
        box-shadow: 0px 1px 0px #d9d9d9;
      }

      .MuiPaper-root {
        background-color: transparent;
      }

      .MuiAccordionDetails-root {
        padding: 0px;
      }

      .MuiExpansionPanelSummary-root {
        padding: 5px;
      }

      .full-content-course {
        h4 {
          font-size: 52px;
        }

        h5 {
          font-size: 28px;
          font-family: $font-secondary;
          font-weight: 400;
          margin-bottom: 10px;
        }

        ul {
          list-style-type: disc;
          padding-left: 20px;
        }

        p,
        li {
          font-family: $font-primary;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: 0.2px;
          padding: 0 0 5px;
        }

        .feature-title {
          font-family: $font-secondary;
          font-size: 22px;
          text-transform: uppercase;
          font-weight: 400;
          margin-bottom: 5px;
        }

        .feature {
          font-family: $font-primary;
          font-size: 17px;
          text-transform: uppercase;
          font-weight: 700;
        }
      }

      .opened {
        border-left: 2px solid $brand-primary;
      }
      .subsection-container {
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 20px;
      }
      .units-title {
        padding-left: 20px;
        padding-top: 10px;
      }
      .featured-content-name {
        font-weight: bold;
      }
    }
  }
}

@media (max-width: 600px) {
  body {
    .course {
      .full-course-program {
        .full-content-course {
          h4 {
            font-size: 32px;
          }

          border-bottom: 1px solid #cecece;
          margin-bottom: 10px;
        }
        .subsection-container {
          padding-top: 20px;
          padding-bottom: 20px;
        }
        .units-title {
          padding-left: 20px;
          padding-top: 10px;
        }
      }
    }
  }
}
