@import '../../../assets/scss/common/common.scss';

body {
  .content-info {
    position: relative;

    .anchor {
      position: absolute;
      top: -50px;
    }

    .grid-container {
      .item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 840px;

        .box-content {
          height: inherit;
          background-image: linear-gradient(to right, rgba(0, 0, 0, 0), black);

          &.right {
            background-image: linear-gradient(to left, rgba(0, 0, 0, 0), black);
          }

          .box-content-container {
            height: inherit;
            align-items: flex-start;

            h2 {
              text-transform: uppercase;
              font-size: 70px;
              line-height: 72px;
              font-family: $font-secondary;
              color: $brand-primary;
              letter-spacing: 4.2px;
              margin-top: 25%;
            }

            p {
              margin-top: 24px;
              color: $white;
              line-height: 24px;
              font-size: 18px;
              font-family: $font-primary;
              letter-spacing: 0.2px;
            }
          }

          .btn-primary {
            margin-top: 24px;
          }
        }
      }
    }
  }
}

// IPAD
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  body {
    .content-info {
      .grid-container {
        .item {
          .box-content {
            .box-content-container {
              .content-info-detail {
                height: inherit;

                .content-info-detail-container {
                  height: 85%;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  padding-bottom: 5%;

                  h2 {
                    margin-top: 10%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  body {
    .content-info {
      .grid-container {
        .item {
          height: 100vh;
          height: calc(var(--vh-nohead, 1vh) * 100);
          padding: 0;
          background-position: center;
          min-height: 540px;

          .box-content {
            height: 100%;

            .box-content-container {
              padding: 16px;
              position: relative;
              height: 100%;

              .content-info-detail {
                padding-bottom: 8px;
                height: 100%;

                .content-info-detail-container {
                  height: calc(var(--vh-nohead, 1vh) * 80);
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                }
              }

              h2 {
                margin: 0;
                font-size: 50px;
                line-height: 56px;
              }

              p {
                margin-top: 0;
                font-size: 15px;
                line-height: 19px;
              }

              .content-button {
                position: absolute;
                bottom: 32px;
                width: calc(100% - 64px);
                padding: 8px;
                text-align: center;
              }
            }

            &.with-link {
              .box-content-container {
                .content-info-detail {
                  padding-bottom: 8px;
                }
              }
            }
          }
        }
      }
    }
  }
}
