@import '../../../../assets/scss/common/common.scss';

body {
  .dropdown-menu-mobile {
    height: auto;

    &.dropdown-menu {
      > li {
        width: 100%;
        position: relative;
        line-height: 40px;

        .icon-right {
          background: url('../../../../assets/images/arrow-right-white.svg')
            no-repeat scroll center center/60% auto rgba(0, 0, 0, 0);
          position: absolute;
          right: 8px;
          width: 20px;
          height: 20px;
          top: 8px;
        }

        &:first-child {
          margin-top: 24px;
        }

        &.back {
          color: $gray;
        }

        &.no-link {
          color: $gray-light;
        }
      }
    }
  }
}
