@import '../../../assets/scss/common/common.scss';

body {
  .course,
  .degree {
    .course-mentor {
      margin: 48px 0;

      .grid-container {
        .item {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          height: 100vh;
          min-height: 650px;

          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;

          h2 {
            font-size: 50px;
            margin-bottom: 16px;
            text-transform: uppercase;
            font-family: $font-secondary;
            color: $brand-primary;
            line-height: 56px;
            letter-spacing: 2.7px;
          }

          h4 {
            font-family: $font-primary;
            font-size: 18px;
            letter-spacing: 1px;
            color: $white;
            line-height: 24px;
          }

          h5 {
            font-size: 16px;
            letter-spacing: 0.6px;
            line-height: 32px;
            color: $white;
            font-family: $font-secondary;
          }

          .box-content {
            height: inherit;
            background-image: linear-gradient(
              to left,
              rgba(0, 0, 0, 0),
              rgba(0, 0, 0, 0.6)
            );

            .mentor-content {
              display: flex;
              flex-direction: column;
              justify-content: center;
            }

            &:hover {
              background-image: linear-gradient(
                to left,
                rgba(0, 0, 0, 0),
                rgba(0, 0, 0, 0.4)
              );

              .non-over {
                display: none;
              }
            }

            .box-content-container {
              height: inherit;
              padding: 0 16px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  body {
    .course,
    .degree {
      .course-mentor {
        .grid-container {
          justify-content: flex-end;
          .item {
            h4 {
              font-size: 16px;
              line-height: 20px;
              letter-spacing: 0.18px;
            }

            .box-content {
              .mentor-content {
                margin-bottom: 10%;
                justify-content: flex-end;
              }
            }
          }
        }
      }
    }
  }
}
