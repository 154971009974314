$font-primary: 'D-DIN' !default;
$font-secondary: 'mono45-headline' !default;
$font-tertiary: 'DIN Condensed' !default;

$brand-primary: #52e0ff;
$brand-secondary: #f94b79;

$gray-extra-dark: #4d4d4d;
$gray-dark: #808080;
$gray: #a5a5a5;
$gray-light: #e0e0e0;
$gray-extra-light: #fafafa;

$white: #fff;
$black: #000;

$brand-hover-primary: #00c3ee;
$brand-active-primary: #6de5ff;

$brand-hover-black: #383838;
