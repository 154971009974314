@import '../../../assets/scss/common/common.scss';

.action-bar {
  width: 100%;

  border: none;

  .MuiGrid-justify-content-xs-space-between {
    border-bottom: 1px solid #cecece;
  }

  .Mui-selected {
    border-bottom: 3px solid #52e0ff !important;
  }

  .black-link {
    .MuiTab-wrapper {
      color: #000;
    }
  }

  .grid-container {
    .tabs-container {
      button {
        span {
          font-size: 16px;
        }
      }
    }

    .right-subscribe {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      > a {
        height: fit-content;
      }
    }

    .price {
      text-align: center;
      h2 {
        line-height: 48px;
      }
    }

    > :first-child {
      display: flex;
    }
  }
}

.course,
.degree {
  .action-bar {
    width: 100%;
    // padding: 40px 0 0;
    // margin-bottom: 80px;

    .grid-container {
      .price {
        display: none;
      }
    }
  }
}

.last-link {
  color: #000 !important;
}

@media (max-width: 600px) {
  .action-bar {
    border-bottom: 1px solid #cecece;

    .MuiGrid-justify-content-xs-space-between {
      border-bottom: none;
    }

    .grid-container {
      .tabs-container {
        margin-left: -16px;
        button {
          span {
            font-size: 12px !important;
          }
        }
      }
    }
  }
}
