@import '../../../../assets/scss/common/common.scss';

body {
  .course,
  .degree {
    .course-content {
      .content {
        margin-top: 64px;
        position: relative;

        section {
          margin-bottom: 80px;
        }

        h2,
        h3 {
          line-height: 46px;
          margin: 0 0 24px;
        }

        p,
        li {
          font-family: $font-primary;
          font-size: 17px;
          line-height: 32px;
          padding: 0 0 24px;
        }

        p {
          line-height: 24px;
        }

        ul {
          list-style: none;
          padding-left: 1em;
          margin-bottom: 40px;
          text-indent: -0.5em;
          li {
            padding-bottom: 4px;

            &:before {
              content: '\2022';
              color: $gray;
              font-weight: bold;
              display: inline-block;
              width: 0.3em;
              font-size: 30px;
            }
          }
        }

        button {
          width: 40%;
          min-height: 46px;
          height: auto;
          /*border: 2px solid #000;*/
          border: none;
          margin: 8px 16px 8px 0;
          /*background-color: white;*/

          font-family: $font-primary;
          /*text-transform: uppercase;*/
          text-align: left;
          font-size: 18px;
          padding: 2px 25px 4px;
          line-height: 17px;

          background: url('../../../../assets/images/circle-check.svg')
            no-repeat left center;
        }

        .habilities {
          margin-bottom: 40px;
        }
        .skills-you-acquire {
          ul {
            display: grid;
            list-style-type: none;
            padding-left: 0px;
            margin-left: 0px;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            li {
              border: solid 2px $black;
              text-align: center;
              margin: 10px;
              padding: 10px 15px 10px 15px;
              font-family: $font-secondary;
              &::before {
                content: '';
              }
            }
          }
        }
      }
    }
  }
}

// IPAD
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  body {
    .course,
    .degree {
      .course-content {
        .content {
          h2,
          h3 {
            font-size: 32px;
            line-height: 40px;
            margin: 8px 0;
          }

          p,
          li {
            font-size: 16px;
            line-height: 20px;
            padding: 0;
          }

          ul {
            margin: 0;
          }

          button {
            width: 100%;
          }
          .skills-you-acquire {
            ul {
              display: grid;
              list-style: none;
              padding-left: 0px;
              margin-left: 0px;
              grid-template-columns: repeat(3, minmax(0, 1fr));
              li {
                border: solid 1px black;
              }
            }
          }
        }
      }
    }
  }
}

// MOBILE
@media (max-width: 600px) {
  body {
    .course,
    .degree {
      .course-content {
        .content {
          margin-top: 32px;
          h2,
          h3 {
            font-size: 32px;
            line-height: 40px;
            margin: 8px 0;
          }

          p,
          li {
            font-size: 16px;
            line-height: 20px;
            padding: 0;
          }

          ul {
            margin: 0;
          }

          button {
            width: 100%;
          }
        }
      }
    }
  }
}

hr {
  border: 1px solid #d8d8d8;
}
