@import '../../../../assets/scss/common/common.scss';

body {
  .menu-list-component {
    display: inline;
    position: relative;
    cursor: pointer;

    > div {
      transform: translate3d(0, 23px, 0px);
      top: 0px;
      left: 0px;
      width: 100%;
    }
  }

  .user-dropdown {
    min-width: 154px;
    cursor: pointer;
    > a {
      display: block;
      width: 100%;
      padding-left: 8px;
    }
  }

  .menu-list-container,
  .MuiMenu-list {
    z-index: 999;
    background-color: $black;
    width: inherit;
    // min-width: 160px;
    li {
      color: $gray;
      font-family: $font-tertiary;
      font-size: 13px;
      letter-spacing: 0.8px;
      text-transform: uppercase;
      padding: 2px 0;

      > a {
        margin: 0;
      }

      &:hover {
        color: $white;
      }
    }
  }
}
