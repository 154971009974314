@import '../../assets/scss/common/common.scss';

body {
  .fullscreen {
    width: 100% !important;
  }

  .question-section {
    margin-top: 88px;
    background-color: #f2f2f2;

    .MuiContainer-root {
      display: flex;
    }

    .container-question {
      flex-direction: column;
      justify-content: center;
      margin-top: 20px;

      min-height: 140px;

      .content-question {
        h4 {
          font-family: $font-primary !important;
          font-style: normal;
          font-weight: 700;
          font-size: 22px;
          line-height: 30px !important;
          text-transform: uppercase !important;
        }

        p {
          font-family: $font-primary !important;
          font-style: normal;
          font-size: 18px;
          line-height: 30px;
        }
      }
    }
  }

  .background-top {
    background-color: $black;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    min-height: 650px;
    top: 0;
    z-index: -1;
    position: absolute;
    width: 100%;
  }

  .control-buttons {
    &.full {
      position: absolute;
      right: 16px;
      top: 16px;
      z-index: 999999;
    }
  }

  .fullscreen {
    position: fixed;
    width: 95%;
    height: 95%;
    z-index: 1000;
  }

  .fullscreen-container {
    z-index: 1000;
  }

  .home-background {
    &.course,
    &.degree {
      margin-top: 0;
      height: 100vh;
      height: calc(var(--vh, 1vh) * 90);
      min-height: 650px;
      margin-top: 110px;
      top: 0;
      z-index: 0;
      position: absolute;
      width: 100%;

      &:after {
        content: '';
        background-image: linear-gradient(
          to left,
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 0.9)
        );
        height: inherit;
        display: block;
      }
    }
  }

  .page {
    &.course,
    &.degree {
      z-index: 1;
      position: relative;
      margin-top: 0;
      h2,
      h3,
      h4 {
        font-family: $font-secondary;
        font-size: 52px;
        color: $brand-primary;
        color: $black;
        margin: 0;
        line-height: 54px;
        letter-spacing: 2px;
      }

      h3 {
        font-size: 32px;
      }

      h4 {
        font-size: 22px;
        margin: 0;
      }

      ul {
        list-style-type: none;
        padding: 0;
      }

      .action-bar-container {
        background-color: $white;
        padding-top: 28px;
        margin-bottom: 40px;
        .title,
        .options {
          display: flex;
          flex-direction: column;
          justify-content: center;

          h2 {
            font-family: $font-secondary;
            font-size: 26px;
            line-height: 32px;
            letter-spacing: 0.8px;
          }

          h3 {
            font-family: $font-secondary;
            font-size: 12px;
            letter-spacing: 0.6px;
            line-height: 18px;
            color: $gray;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  body {
    .question-section {
      margin-top: 40px;
    }

    .content-question {
      h4 {
        font-family: $font-primary !important;
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 26px !important;
        text-transform: uppercase !important;
        padding-top: 10px;
      }

      p {
        margin-top: 16px !important;
        margin-bottom: 24px !important;
      }
    }

    div.background-top {
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
      min-height: 570px;
    }

    .home-background {
      &.course,
      &.degree {
        margin-top: 30px;
        min-height: 570px;
        background-size: cover;
        background-position: 80% 15%;
        height: calc(var(--vh, 1vh) * 98);
      }
    }

    .page {
      &.course,
      &.degree {
        .jumbo-container {
          height: inherit;
          margin-bottom: 16px;
        }

        h2 {
          font-size: 32px;
          line-height: 32px;
          font-weight: 400;
        }

        h3 {
          font-weight: 400;
          font-size: 24px;
          line-height: 24px;
        }

        .action-bar-container {
          margin: 0;
          padding: 0;
          margin-top: 30px;
          background-color: transparent;
          .title {
            display: none;
          }

          .buy-button-actionbar {
            display: none;
          }
        }
      }
    }
  }
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
  body {
    .course-box {
      width: 100%;
    }
  }
}
