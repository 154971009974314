@import '../../../assets/scss/common/common.scss';

body {
  .play-button {
    display: flex;
    flex-direction: column;
    align-items: center;

    svg:hover {
      cursor: pointer;
    }

    .hover-button {
      flex: 1;
      flex-direction: column;
      align-items: center;
    }

    .play-button-text {
      display: none;
    }
  }

  .play-button:hover {
    .play-button-text {
      display: block;
      margin-top: 5px;
      color: $brand-primary;
      font-family: $font-secondary;
      text-transform: uppercase;
      font-size: 14px;
    }
  }
}
