@import '../../../assets/scss/common/common.scss';

body {
  .page {
    &.home {
      .partner-bar {
        position: absolute;
        bottom: -30px;
        left: 5px;
        min-height: 80px;
        width: 100%;
        background-color: $white;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding-top: 5px;
        padding-bottom: 5px;
        z-index: 2000;

        span {
          font-family: $font-secondary;
          text-transform: uppercase;
          font-size: 1.1rem;
          padding-right: 45px;
          line-height: 1.7rem;
        }

        .la-liga-logo {
          width: 6.3rem;
        }

        .marca-logo {
          width: 5.9rem;
        }

        .la-liga-bs-logo {
          width: 8.5rem;
        }

        .radio-logo {
          width: 3.8rem;
        }

        .esue-logo {
          width: 7.9rem;
        }

        .coeducation-logo {
          width: 8.5rem;
        }

        .afe-logo {
          width: 4.8rem;
        }

        .austral-logo {
          width: 9.7rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  body {
    .page {
      &.home {
        .partner-bar {
          bottom: 5px;
          flex-wrap: wrap;

          span {
            width: 100%;
            font-family: $font-secondary;
            text-transform: uppercase;
            font-size: 1.3rem;
            padding-right: 30px;
            line-height: 1.1rem;
            padding-left: 8%;
          }

          img {
            margin-left: 5px;
            margin-right: 5px;
          }

          .la-liga-logo {
            width: 5.3rem;
            margin-left: 2px;
          }

          .marca-logo {
            width: 4.2rem;
          }

          .la-liga-bs-logo {
            width: 7.8rem;
          }

          .radio-logo {
            width: 3.6rem;
          }

          .esue-logo {
            width: 7.8rem;
          }

          .coeducation-logo {
            width: 8.5rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  body {
    .page {
      &.home {
        .partner-bar {
          span {
            margin-top: 5px;
            margin-bottom: 10px;
          }

          flex-direction: row;
          div {
            width: 30%;
            text-align: center;
            padding: 5px;
          }

          .esue-logo {
            width: 6.8rem;
          }

          .la-liga-bs-logo {
            width: 6.4rem;
          }

          .coeducation-logo {
            width: 7.2rem;
          }
        }
      }
    }
  }
}
