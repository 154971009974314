@import '../../../assets/scss/common/common.scss';

body {
  div.buy-button {
    position: relative;
    display: block;
    text-align: center;
    width: 100%;

    .buy-course {
      min-width: 176px;
      color: $black;
      text-align: center;
      width: 90%;
    }

    > p {
      font-size: 12px !important;
      letter-spacing: 0.47px !important;
      font-weight: normal;
      margin: 4px 0 0 0;
      border: none;
      text-align: center;
      padding: 0 !important;
      width: 100% !important;
      text-transform: uppercase;
      border: 0 !important;
      font-family: $font-primary !important;
    }
  }
}
