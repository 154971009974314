@import '../../../../assets/scss/common/common.scss';

.mentor-biography .text-section {
  font-family: $font-primary;
  text-align: justify;
  padding-top: 94px;
  padding-left: 61px;
  padding-bottom: 96px;
  line-height: 28px;
  &.mobile {
    padding-right: 61px;
  }
}
