@import '../../../assets/scss/common/common.scss';

body {
  .reseted {
    display: block !important;
  }

  .degree {
    .course-program {
      margin-top: 104px;

      .title {
        .item {
          display: flex;
          justify-content: center;

          img {
            width: 30px;
          }
        }

        .download-program {
          a {
            float: right;
          }
        }
      }

      h2 {
        margin-bottom: 0;
      }

      p,
      li {
        font-family: $font-primary;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: 0.2px;
        padding: 0 0 16px;
      }

      .content-program {
        margin: 40px 0;

        .structure-container,
        .subsection-container {
          border-bottom: 1px solid $gray-light;
          padding: 54px 0;

          &:last-child {
            border-bottom: none;
          }

          h4 {
            font-family: $font-tertiary;
            text-transform: uppercase;
            line-height: 32px;
          }

          h3 {
            margin: 8px 0 0;
            color: $gray-extra-dark;
            line-height: 32px;
            letter-spacing: 0.6px;
          }

          .more {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .btn-close-open {
              width: 34px;
              height: 34px;
            }
          }
        }

        .structure-container {
          h4,
          h3 {
            font-size: 42px;
            color: $black;
            margin-left: 32px;
          }

          h4 {
            font-size: 22px;
            letter-spacing: 0.76px;
          }
        }

        .structure {
          border-left: 2px solid transparent;
          &:hover {
            border-left: 2px solid $brand-primary;
          }
        }

        h4 {
          font-size: 28px;
          font-family: $font-tertiary;
          font-weight: bold;
          line-height: 32px;
          text-transform: uppercase;
          margin-left: 32px;
        }

        .subsection-container {
          border-bottom: none;

          p {
            margin: 0;
            line-height: 24px;
            letter-spacing: 0.2px;
            padding: 0;
          }

          .expand-icon {
            font-size: 30px;
          }

          h4 {
            letter-spacing: 0.8px;
          }
        }

        .unit-container {
          margin: 24px;

          h4 {
            margin: 0 0 24px;
          }

          .lesson-content-list {
            margin: 24px 0 40px;
            display: flex;

            li {
              display: flex;
              align-items: center;
              margin: 0 12px;
              font-size: 12px;
              font-weight: bold;
              letter-spacing: 0.8px;
              color: $gray-extra-dark;
              padding: 0;

              &:first-child {
                margin-left: 0;
              }

              img {
                margin-right: 10px;
                filter: brightness(40%);
              }

              span {
                font-size: 11px;
                letter-spacing: 0.8px;
                text-transform: uppercase;
                color: $gray-extra-dark;
                font-weight: bold;
              }
            }
          }

          .unit-content-list {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            box-sizing: border-box;

            li {
              flex-grow: 0;
              max-width: 50%;
              flex-basis: 50%;
              display: flex;
              margin-bottom: 32px;

              .unit-container-title {
                text-transform: uppercase;
                font-weight: 600;
                font-size: 16px;
                color: $gray-extra-dark;
                line-height: 24px;
                display: block;
                margin-bottom: 8px;
              }

              img {
                width: 32px;
                height: 32px;
                margin: 0 32px 0 0;
                filter: brightness(40%);
              }

              > div {
                span {
                  font-size: 17px;
                  font-family: $font-primary;
                  color: $gray-dark;
                }
              }
            }
          }
        }
      }

      .opened {
        border-left: 2px solid $brand-primary;
      }

      .MuiPaper-elevation1 {
        box-shadow: none;
      }

      .MuiAccordionDetails-root {
        padding: 0;
      }

      .MuiExpansionPanel-root:before {
        background-color: transparent;
      }

      .MuiExpansionPanelSummary-content {
        margin: 0;
      }

      .MuiExpansionPanelSummary-root,
      .labeled-content {
        border-bottom: 1px solid $gray-light;
      }

      .MuiExpansionPanel-root.Mui-expanded {
        margin: 0;
      }
    }
  }
}
