@import '../../../../assets/scss/common/common.scss';

header {
  .on {
    .static-header {
      background-color: $white;
    }
  }
}
.static-header {
  top: 0;
  padding: 16px 0;
  position: fixed;
  width: 100%;
  z-index: 6;

  &.black {
    background-color: $black;
  }

  .header-container {
    justify-content: space-between;
  }

  .header-logo {
    display: block;
    width: 88px;
    height: 39px;
  }

  .menu {
    text-align: center;
    button {
      min-width: 110px;
    }
  }
  .right-buttons {
    text-align: right;

    a {
      margin-left: 32px;
    }

    .login {
      min-width: 150px;
      text-align: center;
      padding: 0 0 0 8px;
      cursor: pointer;

      &.logged {
        display: flex;
        justify-content: space-between;

        > * {
          display: initial;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 114px;
        }

        &:after {
          margin-right: 8px;
        }
      }

      &:not(button) {
        padding: 6px 0;
        margin: 0;
      }
    }
  }

  &.dark {
    background-image: none;

    .header-logo {
      filter: brightness(0);
      width: 88px;
      height: 56px;
    }

    .btn-tertiary,
    .btn-secondary {
      color: $black;
    }

    .btn-secondary {
      border-color: $black;
    }
  }
}

@media (max-width: 600px) {
  body {
    background-color: $black;

    .static-header {
      padding: 8px 0;
      top: 0;
      background-color: $black;

      .black {
        height: 56px;
      }

      .header-container {
        justify-content: end;

        .menu {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          text-align: right;
        }
      }
    }
  }
}
