@import '../../../../assets/scss/common/common.scss';

.share-email-modal {
  h2 {
    font-family: $font-secondary;
    font-size: 34px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 1.18px;
    max-width: 50%;
  }

  .close {
    position: absolute;
    right: 0;
    top: 0;
  }

  .send-again-box {
    padding: 0 16px;
    color: $gray-dark;
  }

  .send-again {
    text-transform: none;
    color: $black;
    text-decoration: underline;
  }

  .form-input {
    width: 100%;
    border-radius: 0;
  }

  .btn-primary {
    margin-top: 14px;
  }

  .form-input {
    p {
      margin: 4px 0;
      font-size: 14px;
    }
  }

  p {
    font-family: $font-primary;
    font-size: 16px;
    margin: 16px 0;
    border: 1px solid $gray;
    background-color: $gray-extra-light;
    padding: 32px 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .share {
      margin-right: 16px;
      height: 20px;

      &.facebook {
        img {
          width: 90px;
        }
      }

      &.linkedin {
        img {
          width: 80px;
        }
      }

      &.twitter {
        img {
          width: 80px;
        }
      }

      &.email {
        img {
          width: 110px;
        }
      }

      &.link {
        img {
          width: 110px;
        }
      }
    }

    a {
      display: inline;
      color: $brand-primary;
    }

    &.didnt-receive {
      margin: 0;
    }
  }
}

@media (max-width: 600px) {
  body {
    .share-email-modal {
      position: relative;

      h2 {
        max-width: 90%;
      }
      > div:nth-child(3) {
        width: 70%;
      }

      p {
        justify-content: center;
        padding: 0;
        .share {
          padding: 12px !important;
          margin: 0;
          display: block;
          width: 100%;
          text-align: left;
          border-bottom: 1px solid $gray !important;
          height: 50px;

          &:last-child {
            border-bottom: none !important;
          }
        }
      }
    }
  }
}
