@import '../../../../assets/scss/common/common.scss';

body {
  .mentor-biography {
    padding: 0px;
    .quote-container {
      background-color: black;
      height: 644px;
      width: 100%;
      .quote-grid-container {
        height: 100%;
        .quote-grid-item {
          height: 100%;
          .quote-image {
            width: 100%;
            height: 100%;
            background-size: cover;
          }
          .quote-text {
            display: flex;
            align-items: center;
            padding-left: 17%;
            padding-right: 17%;
            height: 100%;
            blockquote {
              font-size: 24px;
              font-family: $font-primary;
              color: white;
              &::before {
                content: '"';
                font-size: 88px;
                font-style: italic;
                margin-left: -30px;
              }
              &::after {
                content: '"';
                display: flex;
                justify-content: end;
                font-size: 88px;
                font-style: italic;
                margin-top: -20px;
                margin-right: -30px;
              }
              p {
                margin-top: -30px;
                font-weight: 400;
                font-size: 1.3rem;
                font-style: italic;
                line-height: 36px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  body {
    .mentor-biography {
      padding: 0px;
      .quote-container {
        background-color: black;
        height: 697px !important;
        width: 100%;
        .quote-grid-container {
          height: 100%;
          .quote-grid-item {
            height: 359px !important;
            .quote-image {
              width: 100%;
              height: 100%;
              background-size: cover;
            }
            .quote-text {
              display: flex;
              align-items: center;
              padding-left: 17%;
              padding-right: 17%;
              height: 100%;
              blockquote {
                &::before {
                  font-size: 44px !important;
                }
                &::after {
                  font-size: 44px !important;
                }
                p {
                  font-size: 1rem !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
