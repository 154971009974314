@import '../../assets/scss/common/common.scss';

body {
  .page {
    &.list {
      z-index: 1;
      position: relative;
      overflow: hidden;
      min-height: 860px;

      .grid-container {
        margin: 54px 0 0;
      }
    }
  }
}

@media (max-width: 600px) {
  body {
    .page {
      &.list {
        .category-box {
          margin-bottom: 8px;
          .category-title {
            &.title {
              border: none;
            }

            &.selector {
              margin: 0 auto;
            }

            &.button-remind {
              flex-direction: column;
              align-items: flex-end;
              justify-content: center;
              display: flex;
            }

            h2 {
              margin: 16px 0;
              font-size: 32px;
            }
          }
        }
        .grid-container {
          margin: 0;
        }
      }
    }
  }
}
