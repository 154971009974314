@import '../../../../assets/scss/common/common.scss';

.payment-method-modal {
  h2 {
    font-family: $font-secondary;
    font-size: 34px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 1.18px;
  }

  p.bottom {
    margin: 32px 0 0;
    color: $gray;
    font-size: 14px;

    a {
      font-size: 14px;
    }
  }

  .close {
    position: absolute;
    right: 0;
    top: 0;
  }

  .form-input {
    width: 100%;
    border-radius: 0;
  }

  .btn-primary {
    margin-top: 14px;
  }

  .form-input {
    p {
      margin: 4px 0;
      font-size: 14px;
    }
  }

  small {
    font-family: $font-primary;
    font-size: 13px;
    font-weight: 400;
  }

  p {
    font-family: $font-primary;
    font-size: 16px;
    margin: 16px 0;
    line-height: 22px;

    a {
      display: inline;
      color: $brand-primary;
    }

    &.didnt-receive {
      margin: 0;
    }
  }

  .container-border {
    border: 1px solid #a5a5a5;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .container-border-dark {
    border: 1px solid #000;
    padding-top: 10px;

    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: white;
    font-family: $font-primary;
    font-weight: 700;
    font-size: 16px;
    min-width: 260px;
  }

  .container-border-dark:hover {
    cursor: pointer;
  }

  .box-button-left {
    margin-right: 15px;
  }
  .box-button-right {
    margin-left: 15px;
  }

  @media (max-width: 600px) {
    .box-button-left {
      margin-right: 0px;
    }
    .box-button-right {
      margin-left: 0px;
      margin-top: 20px;
    }
  }
}
