@import '../../../assets/scss/common/common.scss';

.footer {
  height: 540px;
  background-color: $black;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  margin-top: 64px;
  position: relative;
  z-index: 1;

  &.home {
    margin-top: 24px;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  .content {
    height: 390px;
    display: flex;
    align-items: stretch;

    .logo {
      padding-right: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .footer-logo {
        width: 100%;
        max-width: 138px;
      }
    }

    .footer-links {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h3 {
        color: $brand-primary;
        font-size: 22px;
        text-transform: uppercase;
        line-height: 32px;
        margin-bottom: 16px;
        letter-spacing: 0.2px;
      }

      a {
        font-size: 18px;
        line-height: 44px;
        color: $gray-dark;

        &:hover {
          color: $white;
        }
      }

      .enter-email {
        width: 70%;
      }

      .arrow-right {
        max-width: 44px;
      }
    }

    .footer-data {
      h3 {
        margin-bottom: 16pxpx;
        font-family: $font-tertiary;
      }
    }

    .flag {
      margin-right: 16px;
    }

    .footer-social-icons {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      > a {
        display: block;
        font-size: 12px;
        font-family: $font-primary;
        text-align: center;
        color: $brand-primary;

        img {
          width: 22px;
          height: 22px;
          display: block;
          margin: 0 auto;
        }

        &:hover {
          img {
            filter: grayscale(100%);
          }
        }
      }
    }
  }

  .copyright-container {
    display: block;
    font-size: 14px;
    font-family: $font-primary;
    margin-bottom: 32px;

    display: flex;
    align-items: center;

    strong {
      text-transform: uppercase;
      font-weight: bold;
    }
  }
}

@media (min-width: 600px) and (max-width: 768px) {
  body {
    .footer {
      zoom: 0.7;
      height: 760px;
    }
  }
}

@media (max-width: 600px) {
  body {
    .footer {
      align-items: flex-start;
      padding: 32px 16px 0;
      height: auto;
      background-color: black;

      .content {
        height: auto;
        display: flex;
        flex-direction: column-reverse;

        .footer-links {
          padding: 0;
          display: flex;
          flex-direction: column-reverse;

          .enter-email {
            width: 74%;
          }

          .arrow-right {
            width: 22%;
            max-width: 44px;
          }

          h3 {
            margin: 32px 0 8px;
          }

          a {
            line-height: 36px;
          }
        }

        .footer-data {
          margin-top: 0;
          display: block;
          overflow: hidden;

          .footer-social-icons {
            padding: 32px 0;
            justify-content: space-evenly;
            align-items: center;
          }
        }

        .logo {
          margin: 8px auto;

          > a {
            .footer-logo {
              width: 100%;
              max-width: 80px;
              margin: 32px auto;
            }
          }

          .copyright-container {
            display: block;
            font-size: 14px;
            font-family: $font-primary;
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}
