@import '../../../assets/scss/common/common.scss';

body {
  .more-courses {
    width: 100%;

    .title {
      margin: 72px 0;
    }
  }
}

@media (max-width: 600px) {
  body {
    .more-courses {
      .title {
        margin: 24px 4px;
        word-wrap: break-word;
      }
    }
  }
}
