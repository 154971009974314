@import '../../../assets/scss/common/common.scss';

body {
  .image-slider {
    margin: 16px 0;
    background-color: #191919;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    background-repeat: no-repeat;
    background-attachment: inherit;

    background-size: cover;
    background-position: 15% 15%;

    .content,
    .image-slider-carousel {
      background-repeat: no-repeat;
      background-attachment: inherit;
      background-position: center;
      background-size: cover;
    }

    .content {
      display: none;
      height: inherit;

      .read-more {
        margin-top: 32px;
      }

      .content-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 536px;

        .background-gradient-top,
        .background-gradient-bottom {
          position: absolute;
          top: 0;
          left: 0;
          width: 60%;
          height: 100%;
          background-image: linear-gradient(
            to left,
            rgba(0, 0, 0, 0.05),
            rgba(0, 0, 0, 0.4)
          );
          z-index: -1;
        }

        h2,
        h3 {
          font-family: $font-secondary;
          text-transform: uppercase;
          color: $brand-primary;
        }

        h2 {
          font-size: 50px;
          letter-spacing: 2.7px;
          line-height: 72px;
        }

        h3 {
          font-size: 18px;
          letter-spacing: 1.2px;
          color: $white;
          line-height: 40px;
        }

        p {
          font-family: $font-primary;
          text-transform: none;
          font-size: 19px;
          line-height: 25px;
          color: $white;
          width: 60%;
          letter-spacing: 0.2px;
        }
      }
      &:first-child {
        display: flex;
      }
    }

    .slick-dots {
      bottom: 16px;
      li {
        margin: 0 2px;
        button {
          width: 12px;
          height: 12px;
          border: 1px solid $white;

          &:before {
            content: '';
          }

          &:hover {
            opacity: 0.5;
          }
        }

        &.slick-active {
          button {
            background-color: $white;

            &:hover {
              cursor: default;
              background-color: $white;
            }
          }
        }
      }
    }
  }

  .slick-arrow {
    mask: url('../../../assets/images/arrow-slider.svg');
    width: 48px;
    height: 28px;
    border: none;
    display: block;
    position: absolute;
    cursor: pointer;
    mask-size: cover;
    background-color: $white;
    z-index: 10;

    &.slick-prev {
      left: 16px;
    }

    &.slick-next {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
      margin: 0 0 0 auto;
      right: 16px;
      top: 47%;
    }

    &.slick-next,
    &.slick-prev {
      &:before {
        content: '';
      }
    }

    &:hover,
    &:focus {
      outline: none;
      background: $brand-primary;
    }
  }
}

@media (max-width: 600px) {
  body {
    .image-slider {
      background-repeat: no-repeat;
      background-attachment: inherit;
      background-position: 75%;

      .image-slider-carousel {
        background-repeat: no-repeat;
        background-attachment: inherit;
        background-position: 75%;
      }

      .content {
        background-position: -640px 10%;
        background-size: auto 70%;
        position: relative;

        .content-container {
          height: 640px;
          align-items: flex-end;
          justify-content: flex-end;

          .background-gradient-top,
          .background-gradient-bottom {
            position: absolute;
            top: 0;
            width: 100%;
            height: 60%;
            background-image: linear-gradient(to top, rgba(0, 0, 0, 0), black);
            z-index: 1;
          }

          .background-gradient-bottom {
            bottom: 0;
            top: auto;
            background-image: linear-gradient(
              to bottom,
              rgba(0, 0, 0, 0),
              black
            );
            margin: 0;
          }

          .content-item {
            z-index: 5;
          }

          > div {
            margin-bottom: 60px;
          }

          h2 {
            font-size: 40px;
            letter-spacing: 2.2px;
            line-height: 40px;
            margin-bottom: 8px;
          }

          h3 {
            font-size: 16px;
            line-height: 16px;
          }
          p {
            max-width: 100%;
            font-size: 16px;
            line-height: 18px;
            width: 100%;
          }
        }
      }
    }
  }
}
