body {
  // INPUTS

  .MuiButtonBase-root.Mui-disabled {
    // background-color: #eee;
  }

  .MuiOutlinedInput-input {
    padding: 0 16px;
    height: 34px;
  }

  .MuiInputLabel-outlined {
    font-family: $font-tertiary;
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .MuiOutlinedInput-root {
    position: relative;
    border-radius: 0;
    color: $white;
  }

  .PrivateTabIndicator-colorPrimary-8 {
    background-color: transparent;
  }

  .MuiFormLabel-colorSecondary.Mui-focused {
    color: $gray-extra-dark;
  }

  .MuiInput-colorSecondary.MuiInput-underline:after {
    border-bottom-color: $gray-extra-dark;
  }

  .MuiInputBase-input {
    font-family: $font-primary;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: $white;
  }

  .PrivateNotchedOutline-legend-176 {
    font-family: $font-secondary;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $white;
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
  }

  .MuiInputLabel-outlined {
    z-index: 1;
    transform: translate(14px, 20px) scale(1);
    pointer-events: none;
    color: $gray-extra-dark;
    top: -8px;
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: $white;
  }

  // Sticky Header

  @media (min-width: 600px) {
    .MuiToolbar-regular {
      min-height: 60px;
    }
  }

  .MuiBackdrop-root {
    top: 100px;
  }

  .MuiDrawer-paper {
    z-index: 5;
  }

  .MuiDrawer-paperAnchorTop {
    top: 100px;
  }

  .MuiTab-root {
    padding: 0;
    margin: 0 24px;
    min-width: 0;
  }

  // TABS

  .MuiTab-wrapper {
    font-family: $font-secondary;
    font-size: 20px;
    color: $gray;
    letter-spacing: 0.8px;
  }

  .MuiPaper-root {
    background-color: black;
    border-radius: 0 !important;
  }

  .MuiTab-textColorPrimary {
    &.Mui-selected {
      .MuiTab-wrapper {
        color: $brand-primary;
      }
    }
  }

  .MuiButton-contained {
    box-shadow: none;
    background-color: transparent;
    border: 1px solid black;
    border-radius: 0;
    font-family: $font-secondary;
    padding: 8px 24px;
  }

  // CHIPS

  .MuiChip-outlined {
    border: 2px solid #000;
    background-color: transparent;
    border-radius: 4px;
    padding: 8px 24px;
    height: auto;
    margin: 0 16px 16px 0;

    span {
      font-size: 18px;
      font-family: $font-tertiary;
      text-transform: uppercase;
    }
  }
}

.MuiButton-root.Mui-disabled {
  > .MuiButton-label {
    .slick-arrow {
      opacity: 0.4;
    }
  }
}

@media (max-width: 600px) {
  body {
    .MuiDrawer-paperAnchorTop {
      top: 88px;
      height: calc(100% - 88px);
    }

    .MuiTab-root {
      padding: 0;
      margin: 0 16px;
      min-width: 0;
    }
  }
}
