@import '../../../assets/scss/common/common.scss';

body {
  .category-box {
    .category-title {
      border-bottom: 1px solid $gray-light;

      .tabs-container {
        min-height: 52px;
        overflow: initial;

        .tab-container-disabled {
          position: relative;

          .box-coming-soon {
            position: absolute;
            bottom: 0;
            text-transform: uppercase;
            font-family: $font-primary;
            font-size: 10px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            width: 80px;
            left: calc(50% - 40px);
            color: $gray;
            text-align: center;
          }
        }

        > div {
          overflow: initial !important;
        }

        .PrivateTabIndicator-root-7 {
          bottom: -2px;
        }
      }

      .disabled:hover .box-coming-soon {
        display: block;
      }

      h2,
      h3,
      h4 {
        font-family: $font-secondary;
        font-size: 52px;
        color: $black;
        margin: 0;
        line-height: 34px;
        letter-spacing: 2px;
      }

      h3 {
        font-size: 32px;
      }

      h4 {
        font-size: 22px;
        margin: 0;
      }

      .coming-soon-title {
        padding: 0 0 8px;
        > h2 {
          color: $gray;
          display: inline-block;
        }

        button.remind-me {
          color: $black;
        }

        .coming-soon-icon {
          width: 104px;
          height: 24px;
          margin: 0 0 8px 16px;
        }
      }
    }

    .box-coming-soon {
      display: inline-block;
      position: relative;
      p {
        position: absolute;
        top: 6px;
        left: 28px;
        text-transform: uppercase;
        font-family: $font-primary;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.09px;
      }
    }
  }
}

@media (max-width: 800px) {
  body {
    .category-box {
      .category-title {
        .coming-soon-title {
          .coming-soon-icon {
            visibility: hidden;
            margin: 0;
          }

          p {
            left: 0;
            top: -8px;
            color: $gray-dark;
          }
        }
      }

      .box-coming-soon {
        display: block;
      }
    }
  }
}
