@import '../../../../assets/scss/common/common.scss';

$columnWidth: 110px;

.menu-container-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 8px;

  .menu-mobile-buttons {
    position: fixed;
    width: calc(100% - 32px);
    bottom: 0;

    a.btn-secondary {
      display: block;
      margin: 0 0 4px;
      padding: 4px 0;
      text-align: center;

      &:last-child {
        margin-bottom: 8px;
      }
    }
  }

  .search-container {
    margin-top: 40px;
    margin-bottom: 8px;

    .main-search {
      width: 330px;
    }

    .search-button {
      position: absolute;
    }
    .icon-search {
      margin-top: -2px;
      margin-left: -70px;
    }

    .explore-courses {
      color: white;
      border-color: white;
    }
  }

  ul {
    li {
      font-family: $font-tertiary;
      text-transform: uppercase;
      color: $white;
      border-bottom: 1px solid $white;
      width: 100%;
      font-size: 18px;

      &:hover {
        color: $brand-primary;
      }
      &:first-child {
        margin-top: 16px;
        border-top: 1px solid $white;
      }
      &:last-child {
        margin-bottom: 16px;
        border-bottom: 1px solid $white;
      }

      a {
        font-size: 17px;
        letter-spacing: 0;
        padding: 4px 0 2px;
        line-height: 32px;
      }
    }
  }
}

// IPAD AND MOBILE
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1),
  (max-width: 600px) {
  body {
    .menu-container-mobile {
      color: $white;

      .search-container {
        margin-top: 32px;
        margin-bottom: 8px;

        .main-search {
          width: 100%;
          margin-right: 0;

          input {
            font-family: $font-tertiary;
            text-transform: uppercase;
            font-size: 19px;
            letter-spacing: 0.2px;
            color: $brand-primary;
          }
        }

        .explore-courses {
          width: 100%;
          padding-right: 0;
          padding-left: 0;
          text-align: right;
          border: none;
        }
      }
    }
  }
}
