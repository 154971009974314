@import '../../assets/scss/common/common.scss';

body {
  .background-top {
    background-color: $black;
    height: 100vh;
    top: 0;
    z-index: -1;
    position: absolute;
    width: 100%;
  }

  .home-background {
    &.degree {
      height: 100vh;
      min-height: 650px;
      margin-top: 0;
      top: 0;
      z-index: 0;
      position: absolute;
      width: 100%;

      &:after {
        content: '';
        background-image: linear-gradient(
          to left,
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 0.7)
        );
        height: inherit;
        display: block;
      }
    }
  }

  .anchor {
    visibility: hidden;
  }

  .page {
    &.degree {
      z-index: 1;
      position: relative;
      min-height: auto;

      h2,
      h3,
      h4 {
        font-family: $font-secondary;
        font-size: 52px;
        color: $brand-primary;
        color: $black;
        margin: 0;
        line-height: 54px;
        letter-spacing: 2px;
      }

      h3 {
        font-size: 32px;
      }

      h4 {
        font-size: 22px;
        margin: 0;
      }
    }
  }
}
