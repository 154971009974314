@import '../../../assets/scss/common/common.scss';

body {
  .about-detail {
    font-family: $font-primary;

    .mobile-title-box {
      display: none;
    }

    h2,
    h3 {
      font-family: $font-tertiary;
      text-transform: uppercase;
    }

    h1 {
      font-family: $font-secondary;
      font-size: 82px;
      line-height: 70px;
      letter-spacing: 4.2px;
      color: $brand-primary;
      margin-bottom: 24px;
    }

    h2 {
      font-size: 22px;
      font-weight: bold;
      font-stretch: condensed;
      letter-spacing: 0.8px;
      line-height: 24px;
      margin-bottom: 24px;
    }

    h3 {
      font-size: 32px;
      font-weight: bold;
      font-stretch: condensed;
      letter-spacing: 1.2px;
      margin-bottom: 40px;
    }

    p {
      font-size: 20px;
      line-height: 28px;
      text-align: justify;
      margin-bottom: 24px;
    }

    ul {
      li {
        line-height: 32px;
        b {
          font-weight: bold;
        }
      }
    }

    .container {
      background-color: $white;
    }

    .category-title {
      border-bottom: 1px solid $gray-light;
      margin-bottom: 32px;

      .back-button {
        margin-top: 80px;
        margin-bottom: 24px;
      }
    }

    .bio-photo {
      height: 630px;
    }

    .top-detail {
      padding: 32px 0;
      border-bottom: 1px solid $gray-light;
      margin-bottom: 56px;
    }

    .content {
      margin: 48px 0;

      > div:first-child {
        h3 {
          border-top: 1px solid $gray-light;
          padding-top: 32px;
        }
      }
    }

    .back-button-footer {
      text-align: right;
    }
  }
}

@media (max-width: 600px) {
  body {
    .about-detail {
      .category-title {
        border-bottom: none;
        margin-bottom: 0;

        .back-button {
          margin: 32px 0;
        }
      }

      h1 {
        display: none;
      }

      h2 {
        margin: 24px 0 8px;
      }

      .achivements {
        border: 1px solid $gray-light;
        padding: 16px;

        h2 {
          margin: 0;
          font-size: 24px;
          line-height: 40px;
        }

        ul {
          li {
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.2px;
          }
        }
      }

      .story-title {
        margin: 32px 0;
        border-bottom: 1px solid $gray-light;
        padding-bottom: 8px;
      }

      .mobile-title-box {
        display: block;
        h1 {
          display: block;
          font-size: 39px;
          line-height: 36px;
          letter-spacing: 2.2px;
          margin: 24px 0;
        }
      }

      h3 {
        font-size: 24px;
        font-weight: bold;
        font-stretch: condensed;
        letter-spacing: 0.6px;
      }

      .top-detail {
        padding: 0;
        border-bottom: 1px solid $gray-light;
        margin-bottom: 32px;
      }

      p {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.5px;
      }

      .bio-photo {
        height: 420px;
      }

      .back-button-footer {
        margin-bottom: 0;
        a {
          display: block;
          text-align: center;
        }
      }
    }
  }
}
